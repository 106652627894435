import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import Headline3 from '../Typography/Heading/Headline3';
import { Breadcrumbs } from '../Breadcrumb';
import { Grid, GridCell } from '../Grid';
import breakpoints from '../Theme/breakpoints';

const StyledHeadline3 = styled(Headline3)`
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 230px);
  word-break: break-word;
  justify-content: center;

  &&& {
    margin: 56px 0;
  }

  @media (min-width: ${breakpoints.sm}px) {
    min-height: auto;
  }
`;

const tagPagePropertyTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
  children: PropTypes.node,
};

type TagPageProperties = InferProps<
  typeof tagPagePropertyTypes
>;

const TagPage = (
  { title, breadcrumbs, children }: TagPageProperties,
) => (
  <Grid>
    <GridCell padding="0 20px">
      <Breadcrumbs crumbs={breadcrumbs} />
    </GridCell>
    <GridCell padding="16px">
      <StyledHeadline3 tag="h1">
        {title}
      </StyledHeadline3>
    </GridCell>
    <GridCell container spacing={4}>
      {children}
    </GridCell>
  </Grid>
);

TagPage.propTypes = tagPagePropertyTypes;

TagPage.defaultProps = {
  children: undefined,
};

export default TagPage;
